<template>
  <div class="verify-layout d-flex flex-column justify-content-center">
    <div class="register-title">
      <div class="register-title d-flex align-items-center mb-2">
        <div class="auth-logo">
          <img
            :src="appFaviconImage"
            width="32px"
            alt="Logo Ecomdy"
            class="mr-1"
          >
        </div>
        <p class="font-weight-600 font-24 mb-0">
          Hi, Welcome to
          <span class="highlight-content"> Ecomdy Media </span>
        </p>
      </div>
      <p class="title-desc">
        Start your successful TikTok Ads journeys by creating Ecomdy account now!
      </p>
    </div>
    <validation-observer
      ref="verifyEmailValidation"
      v-slot="{ invalid }"
    >
      <b-form
        name="register-form"
        class="verify-email-form"
      >
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label class="form-label">
              {{ $t("common.labelEmail") }}
            </label>
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('common.labelEmail')"
            rules="required|email"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                v-model.trim="userEmail"
                id="user-email"
                name="userEmail"
                class="input-height form-input"
                :placeholder="$t('forgotPW.placeholderEmail')"
                :state="errors.length > 0 ? false : null"
              />
            </b-input-group>
            <small class="text-danger input-group">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <div class="d-flex justify-content-between">
            <label class="form-label">
              {{ $t("common.labelVerifyCode") }}
            </label>
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('common.labelVerifyCode')"
            rules="required||validVerifyCode"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                v-model.trim="verifyCode"
                id="verify-code"
                name="verifyCode"
                class="input-height form-input"
                :placeholder="$t('common.placeholderVerifyCode')"
                :state="errors.length > 0 ? false : null"
              />
              <b-input-group-append is-text>
                <p
                  v-if="expiresIn > 0"
                  class="send-code-btn mb-0 send-code-btn-enable"
                >
                  Resend code {{ expiresIn }}s
                </p>
                <p
                  v-else
                  class="send-code-btn mb-0"
                  :class="!userEmail ? 'send-code-btn-disable' : 'send-code-btn-enable'"
                  variant="outline-secondary"
                  :disabled="!userEmail"
                  @click="handleSendCodeVerifyEmail"
                >
                  Send code
                </p>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger input-group">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <p class="otp-note">
          {{ $t('common.otpNote') }}
        </p>

        <div class="checkbox">
          <b-form-checkbox
            v-model="selected"
            class="custom-control-primary"
            @click="!selected"
          >
            {{ $t('register.textAcceptVerify') }}
            <a
              target="_blank"
              :href="termsOfService"
            >{{ $t('register.textTermsOfService') }}</a>
          </b-form-checkbox>
        </div>

        <div v-if="messageSendCodeError">
          <error-tag :error="messageSendCodeError || ''" />
        </div>

        <btn-loading
          id="trigger-register"
          span-id="trigger-register-child"
          class=" btn-register input-height font-medium txt-color mt-2"
          variant-convert="btn-submit"
          block
          type="submit"
          :loading="loading"
          :disabled="invalid || !selected"
          @click.prevent="signUpByCode"
        >
          {{ $t("register.btnRegisterNow") }}
        </btn-loading>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import { required, email, validVerifyCode } from '@validations'
import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormCheckbox,
} from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import envMixin from '@/mixins/envMixin'
import { createNamespacedHelpers } from 'vuex'
import { appCenter } from '@/router'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { ERROR_MSG } from '@tiktok-service/app-center-connect'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_CAPTCHA_KEY,
})

export default {
  components: {
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormCheckbox,

    // components
    BtnLoading,

    // validate
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [envMixin, toastification],
  data() {
    return {
      messageSendCodeError: '',
      userEmail: '',
      verifyCode: '',
      selected: false,
      recaptchaLoaded: false,
      expiresIn: 0,
      tokenRecaptcha: '',

      required,
      email,
      validVerifyCode,
    }
  },
  computed: {
    ...mapGetters(['status', 'message', 'loading', 'dataAccessToken']),
    appFaviconImage() {
      return process.env.VUE_APP_FAVICON
    },
  },

  methods: {
    ...mapActions(['sendCodeVerifyEmail', 'verifyEmailByCode']),
    async handleSendCodeVerifyEmail() {
      try {
        await this.$recaptchaLoaded()
        this.tokenRecaptcha = await this.$recaptcha('verifyEmailByCode')
        const sessionToken = await appCenter.getSessionToken()
        if (sessionToken) {
          const params = {
            tokenRecaptcha: this.tokenRecaptcha,
            email: this.userEmail,
            sessionToken,
          }
          await this.sendCodeVerifyEmail(params)
          if (!this.status) {
            this.toastFailure(this.message)
          } else {
            this.toastSuccess(this.$t('register.sendCodeSuccess'))
            this.startCountdown()
          }
        }
      } catch (err) {
        if (err.message === ERROR_MSG.getSessionToken.timeout) {
          // handle timeout error
        }
      }
    },

    async signUpByCode() {
      try {
        await this.$recaptchaLoaded()
        this.tokenRecaptcha = await this.$recaptcha('verifyEmailByCode')
        const sessionToken = await appCenter.getSessionToken()
        if (sessionToken) {
          const params = {
            tokenRecaptcha: this.tokenRecaptcha,
            email: this.userEmail,
            sessionToken,
            verifyCode: this.verifyCode,
          }
          await this.verifyEmailByCode(params)
          if (!this.status) {
            this.toastFailure(this.message)
          } else if (this.dataAccessToken?.accessToken) {
            localStorage.setItem('id_token', this.dataAccessToken?.accessToken)
            this.$router.push('/')
          }
        }
      } catch (err) {
        if (err.message === ERROR_MSG.getSessionToken.timeout) {
          // handle timeout error
        }
      }
    },

    startCountdown() {
      this.expiresIn = 60
      const intervalActive = setInterval(() => {
        this.expiresIn -= 1
        if (this.expiresIn <= 0) {
          clearInterval(intervalActive)
        }
      }, 1000)
    },
  },
}
</script>
<style lang="scss" scoped>

.title-desc {
  margin-bottom: 30px;
}

.verify-email-form {
  margin-top: 20px;
}

.verify-layout {
  width: 700px;
  height: 100vh;
  margin: 0 auto;
}

.title-color {
  color: rgba(255, 255, 255, 0.5);
}

.highlight-content {
  font-weight: 700;
  background-image: linear-gradient(128.33deg, #81fff4 -29.89%, #6e44ff 98.77%);
  background-clip: text;
  color: transparent;
}

.auth-input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.otp-note {
  font-style: italic;
  font-size: 12px;
  margin-top: 6px;
  color: rgba(0, 0, 0, 0.7);
}

.verify-email-field {
  margin-top: 20px;
}

.send-code-btn-enable {
  background: linear-gradient(128.33deg, var(--button-color-start) -29.89%, var(--button-color-end) 98.77%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.send-code-btn-disable {
  color: #e0e0e5;
  cursor: not-allowed;
}
</style>
